import { createStore } from 'vuex'
import SharedInfoStore from './sharedInfo'
import UserStore from './user'
import Drawer from './drawer'

export const store = createStore({
    modules: {
        UserStore,
        SharedInfoStore,
        Drawer,
    },
})
