import axios from 'axios'
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerBall from 'quasar/src/components/spinner/QSpinnerBall.js';
import router from './router'
import { MyNotify } from './utility'

const http = axios.create({
    baseURL: '',
})

let requestCount = 0

const showLoading = () => {
    if (requestCount === 0) {
        Loading.show({
            spinner: QSpinnerBall,
            customClass: 'secondary',
            message: 'Processando...',
        })
    }
    requestCount++
}

const hideLoading = () => {
    requestCount--
    if (requestCount === 0) {
        Loading.hide()
    }
}

http.interceptors.request.use(
    c => {
        let jwt = localStorage.getItem('tk_3000')
        if (!c.url.search(window.URL_API_NODEJS) && jwt)
            c.headers.Authorization = `Bearer ${jwt}`
        showLoading()
        return c
    },
    err => {
        hideLoading()
        return Promise.reject(err)
    },
)

http.interceptors.response.use(
    c => {
        hideLoading()
        return c
    },
    err => {
        hideLoading()
        if (err.response.status === 401) router.push('/login')
        if (err.response.data.message === 'CNPJ já registrado') {
            MyNotify('O CNPJ informado já foi cadastrado')
            return Promise.reject(err)
        }
        if (err.response.data.message === 'NCM não existe.') {
            MyNotify(
                'Ocorreu um erro!',
                'negative',
                'O NCM informado não existe.',
            )
            return Promise.reject(err)
        }
        if (err.response.status !== 404) {
            MyNotify(
                'Ocorreu um erro, portanto, entre em contato com o suporte',
                'negative',
                err.response.data.message,
            )
        }
        return Promise.reject(err)
    },
)

export default http
