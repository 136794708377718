window.__VUE_PROD_DEVTOOLS__ = false
import Quasar from 'quasar/src/vue-plugin.js';
import { createApp } from 'vue'
import VueTyper from 'vue3-typer'
import App from './App.vue'
import VueExcelEditor from './components/Vue3ExcelEditor/main'
import quasarUserOptions from './quasar-user-options'
import router from './router'
import './variables'
import { store } from './vuex/index'

const app = createApp(App)

// TODO: Check if this will remove the errors
app.config.productionTip = false

app.use(VueTyper)
app.use(VueExcelEditor)

app.use(Quasar, quasarUserOptions)

app.use(router)

app.use(store)

app.config.globalProperties.$filters = {
    date_dd_mm_yy_without_slash(val) {
        return val.replace(/(\d{2})?(\d{2})?(\d{2})/, '$1/$2/$3')
    },
    cnpj(val) {
        return val
            ? val.replace(
                  /(\d{2})?(\d{3})?(\d{3})(\d{4})?(\d{2})/,
                  '$1.$2.$3/$4-$5',
              )
            : ''
    },
    get_year_of_date_iso(val) {
        return new Date(val).getUTCFullYear()
    },
    get_month_of_date_iso(val) {
        return new Date(val).getUTCMonth()
    },
    get_day_of_date_iso(val) {
        return new Date(val).getUTCDate()
    },
    money(val) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(val)
    },
}

app.mount('#app')
