import Home from '@/views/Home/Home.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from './utility'

const routes = [
    {
        path: '/',
        component: Home,
        name: 'home',
        meta: {
            title: 'Home',
        },
    },
    {
        path: '/login',
        component: () => import('./views/Login/Login.vue'),
        name: 'login',
        meta: {
            title: 'Login',
        },
    },
    {
        path: '/reset-password/:token',
        component: () => import('./views/ResetPassword/ResetPassword.vue'),
        name: 'reset-password',
        meta: {
            title: 'Alteração de Senha',
        },
    },
    {
        path: '/users',
        component: () => import('@/views/Users/Users.vue'),
        name: 'usuarios',
        meta: {
            title: 'Usuários',
        },
    },
    {
        path: '/clients',
        component: () => import('@/views/Clients/Clients.vue'),
        name: 'clientes',
        meta: {
            title: 'Clientes',
        },
    },
    {
        path: '/checker-simple',
        component: () => import('@/views/SimpleChecker/SimpleChecker.vue'),
        name: 'consulta-ao-simples',
        meta: {
            title: 'Consulta ao Simples',
        },
        children: [
            {
                path: 'batches',
                component: () => import('@/views/SimpleChecker/Batches.vue'),
                meta: {
                    title: 'Lotes de Consulta',
                },
            },
            {
                path: 'new-batch',
                component: () => import('@/views/SimpleChecker/NewBatch.vue'),
                meta: {
                    title: 'Nova Busca',
                },
            },
            {
                path: 'export',
                component: () => import('@/views/SimpleChecker/Export.vue'),
                meta: {
                    title: 'Exportar',
                },
            },
        ],
    },
    {
        path: '/database-update',
        component: () => import('@/views/DatabaseUpdate/DatabaseUpdate.vue'),
        name: 'atualizacao-de-base',
        meta: {
            title: 'Atualização de Base',
        },
        children: [
            {
                path: 'classify-items',
                component: () =>
                    import('@/views/DatabaseUpdate/ClassifyItems.vue'),
                meta: {
                    title: 'Itens a Classificar',
                },
            },
            {
                path: 'update-items',
                component: () =>
                    import('@/views/DatabaseUpdate/UpdateItems.vue'),
                meta: {
                    title: 'Atualizar Itens',
                },
            },
            {
                path: 'bulk-update',
                component: () =>
                    import('@/views/DatabaseUpdate/BulkUpdate.vue'),
                meta: {
                    title: 'Atualização em Massa',
                },
            },
            {
                path: 'ncm-movement-items',
                component: () =>
                    import('@/views/DatabaseUpdate/NcmMovementItems.vue'),
                meta: {
                    title: 'Movimentação NCM',
                },
            },
        ],
    },
    {
        path: '/xml-nfe',
        component: () => import('./views/XmlNfe/XmlNfe.vue'),
        name: 'xml-nfe',
        meta: {
            title: 'XML NFe',
        },
        children: [
            {
                path: 'admin',
                component: () =>
                    import('@/views/XmlNfe/AdministrativePanel.vue'),
                meta: {
                    title: 'Painel Administrativo',
                },
            },
            {
                path: 'find-xml',
                component: () => import('@/views/XmlNfe/SearchXml.vue'),
                meta: {
                    title: 'Buscar XML',
                },
            },
            {
                path: 'download-xml',
                component: () => import('@/views/XmlNfe/ListBatch.vue'),
                meta: {
                    title: 'Exportar XMLs',
                },
            },
            {
                path: 'financial-report',
                component: () => import('@/views/XmlNfe/FinancialReport.vue'),
                meta: {
                    title: 'Relatório Financeiro',
                },
            },
        ],
    },
    {
        path: '/icms-fine-diagnosis',
        component: () =>
            import('./views/IcmsFineDiagnosis/IcmsFineDiagnosis.vue'),
        name: 'icms-fine-diagnosis',
        meta: {
            title: 'Diagnóstico de ICMS',
        },
        children: [
            {
                path: 'import-sped',
                component: () =>
                    import('@/views/IcmsFineDiagnosis/ImportSped.vue'),
                meta: {
                    title: 'Importar SPED',
                },
            },
            {
                path: 'generate-diagnosis',
                component: () =>
                    import('@/views/IcmsFineDiagnosis/GenerateDiagnosis.vue'),
                meta: {
                    title: 'Diagnóstico',
                },
            },
            {
                path: 'list-diagnosis',
                component: () =>
                    import('@/views/IcmsFineDiagnosis/ListDiagnosis.vue'),
                meta: {
                    title: 'Exportar',
                },
            },
        ],
    },
    {
        path: '/funds',
        component: () => import('@/views/Funds/Funds.vue'),
        name: 'verbas',
        meta: {
            title: 'Verbas',
        },
        children: [
            {
                path: 'import-manad',
                component: () => import('@/views/Funds/ImportManad.vue'),
                meta: {
                    title: 'Importar MANAD',
                },
            },
            {
                path: 'classify-rubrics',
                component: () => import('@/views/Funds/ClassifierRubrica.vue'),
                meta: {
                    title: 'Classificar Rubricas',
                },
            },
            {
                path: 'generate-calculations',
                component: () => import('@/views/Funds/GenerateCalculus.vue'),
                meta: {
                    title: 'Gerar Calculos',
                },
            },
            {
                path: 'export-rubrics',
                component: () => import('@/views/Funds/ClassifierRubrica.vue'),
                meta: {
                    title: 'Exportar Rubricas',
                },
            },
            {
                path: 'list-calculations',
                component: () => import('@/views/Funds/ListCalculusFunds.vue'),
                meta: {
                    title: 'Exportar Calculos',
                },
            },
        ],
    },
    {
        path: '/account',
        component: () => import('@/views/Home/Account.vue'),
        name: 'conta',
        meta: {
            title: 'Perfil',
        },
    },
    {
        path: '/contact-us',
        component: () => import('@/views/ContactUs/ContactUs.vue'),
        name: 'fale-conosco',
        meta: {
            title: 'Fale Conosco',
        },
    },
    {
        path: '/subsidy',
        component: () => import('@/views/Subsidy/Subsidy.vue'),
        name: 'subvencao',
        meta: {
            title: 'Subvenção',
        },
        children: [
            {
                path: 'import-sped',
                component: () => import('@/views/Subsidy/ImportSped.vue'),
                meta: {
                    title: 'Importar SPED',
                },
            },
            {
                path: 'calculate-simplified',
                component: () => import('@/views/Subsidy/GenerateCalculus.vue'),
                meta: {
                    title: 'Calcular Simplificado',
                },
            },
            {
                path: 'generate-items-data',
                component: () => import('@/views/Subsidy/GenerateAliquots.vue'),
                meta: {
                    title: 'Gerar Itens e Dados',
                },
            },
            {
                path: 'classify-items',
                component: () =>
                    import('@/views/Subsidy/ClassifierAliquots.vue'),
                meta: {
                    title: 'Classificar itens',
                },
            },
            {
                path: 'generate-calculations',
                component: () => import('@/views/Subsidy/GenerateCalculus.vue'),
                meta: {
                    title: 'Calcular Completo',
                },
            },
            {
                path: 'data-validation-list',
                component: () => import('@/views/Subsidy/ListAliquots.vue'),
                meta: {
                    title: 'Validação de dados',
                },
            },
            {
                path: 'calculations-list',
                component: () =>
                    import('@/views/Subsidy/ListCalculusSubsidy.vue'),
                name: 'subvencao',
                meta: {
                    title: 'Exportar calculos de subvenção',
                },
            },
        ],
    },
    {
        path: '/simple',
        component: () => import('@/views/Simple/Simple.vue'),
        name: 'simples-nacional',
        meta: {
            title: 'Simples Nacional',
        },
        children: [
            {
                path: 'import-sped',
                component: () => import('@/views/Simple/ImportSped.vue'),
                meta: {
                    title: 'Importar SPED',
                },
            },
            {
                path: 'analyze-suppliers',
                component: () => import('@/views/Simple/SupplierAnalysis.vue'),
                meta: {
                    title: 'Analisar fornecedores',
                },
            },
            {
                path: 'classify-products',
                component: () => import('@/views/Simple/CstClassifier.vue'),
                meta: {
                    title: 'Classificar produtos',
                },
            },
            {
                path: 'generate-calculations',
                component: () => import('@/views/Simple/GenerateCalculus.vue'),
                meta: {
                    title: 'Gerar cálculos',
                },
            },
            {
                path: 'export-supplier-analysis-list',
                component: () => import('@/views/Simple/ListSuppliers.vue'),
                meta: {
                    title: 'Análise de fornecedores',
                },
            },
            {
                path: 'export-calculations-list',
                component: () => import('@/views/Simple/ListCalculus.vue'),
                meta: {
                    title: 'Lista de cálculos',
                },
            },
        ],
    },
    {
        path: '/food-basket',
        component: () => import('@/views/FoodBasket/FoodBasket.vue'),
        name: 'food-basket',
        meta: {
            title: 'Transferência de Itens Isentos',
        },
        children: [
            {
                path: 'import-sped',
                component: () => import('@/views/FoodBasket/ImportSped.vue'),
                meta: {
                    title: 'Importar SPED',
                },
            },
            {
                path: 'extract-items',
                component: () => import('@/views/FoodBasket/ExtractItems.vue'),
                meta: {
                    title: 'Extrair itens',
                },
            },
            {
                path: 'classify-products',
                component: () =>
                    import('@/views/FoodBasket/ClassifyProducts.vue'),
                meta: {
                    title: 'Classificar produtos',
                },
            },
            {
                path: 'generate-calculations',
                component: () =>
                    import('@/views/FoodBasket/GenerateCalculus.vue'),
                meta: {
                    title: 'Gerar cálculos',
                },
            },
            {
                path: 'export-calculations',
                component: () => import('@/views/FoodBasket/ListCalculus.vue'),
                meta: {
                    title: 'Exportar cálculos',
                },
            },
        ],
    },
    {
        path: '/help/clients',
        component: () => import('@/views/Help/HelpClients.vue'),
        name: 'ajuda-clientes',
        meta: {
            title: 'Ajuda - Clientes',
        },
    },
    {
        path: '/help/xml-nfe',
        component: () => import('@/views/Help/HelpXmlNfe.vue'),
        name: 'ajuda-xml-nfe',
        meta: {
            title: 'Ajuda - XML NFe',
        },
    },
    {
        path: '/help/database-update',
        component: () => import('@/views/Help/HelpDatabaseUpdate.vue'),
        name: 'ajuda-atualizacao-de-base',
        meta: {
            title: 'Ajuda - Atualização de base',
        },
    },
    {
        path: '/help/subsidy',
        component: () => import('@/views/Help/HelpSubsidy.vue'),
        name: 'ajuda-subvencao',
        meta: {
            title: 'Ajuda - Subvenção',
        },
    },
    {
        path: '/help/funds',
        component: () => import('@/views/Help/HelpFunds.vue'),
        name: 'ajuda-verbas',
        meta: {
            title: 'Ajuda - Verbas',
        },
    },
    {
        path: '/help/credit-pis-cofins',
        component: () => import('@/views/Help/HelpCreditPisCofins.vue'),
        name: 'ajuda-credito-pis-cofins',
        meta: {
            title: 'Ajuda - Pis/Cofins s/ Icms ST',
        },
    },
    {
        path: '/help/simple',
        component: () => import('@/views/Help/HelpSimple.vue'),
        name: 'ajuda-simples',
        meta: {
            title: 'Ajuda - Simples Nacional',
        },
    },
    {
        path: '/help/icms-fine-diagnosis',
        component: () => import('@/views/Help/HelpIcmsFineDiagnosis.vue'),
        name: 'ajuda-autuacoes-icms',
        meta: {
            title: 'Ajuda - Diagnóstico de ICMS',
        },
    },
    {
        path: '/help/checker-simple',
        component: () => import('@/views/Help/HelpSimpleChecker.vue'),
        name: 'ajuda-consulta-ao-simples',
        meta: {
            title: 'Ajuda - Consulta ao Simples',
        },
    },
    {
        path: '/help/food-basket',
        component: () => import('@/views/Help/HelpFoodBasket.vue'),
        name: 'ajuda-cesta-basica',
        meta: {
            title: 'Ajuda - Transferência de Itens Isentos',
        },
    },
    {
        path: '/news/create',
        component: () => import('@/views/News/NewsPageCreate.vue'),
        name: 'newatualizacoes',
        meta: {
            title: 'Atualizações',
        },
    },
    {
        path: '/news/edit',
        component: () => import('@/views/News/NewsPageEdit.vue'),
        name: 'atualizacoes',
        meta: {
            title: 'Atualizações',
        },
    },
    {
        path: '/credit-pis-cofins',
        component: () => import('@/views/CreditPisCofins/Home.vue'),
        name: 'credito-pis-cofins',
        meta: {
            title: 'Pis/Cofins s/ Icms ST',
        },
        children: [
            {
                path: 'import-sped',
                component: () =>
                    import('@/views/CreditPisCofins/ImportSPED.vue'),
                meta: {
                    title: 'Importar SPED',
                },
            },
            {
                path: 'import-xml',
                component: () =>
                    import('@/views/CreditPisCofins/ImportXML.vue'),
                meta: {
                    title: 'Importar XML',
                },
            },
            {
                path: 'xml-list',
                component: () =>
                    import('@/views/CreditPisCofins/GenerateXMLList.vue'),
                meta: {
                    title: 'Gerar Lista de XML',
                },
            },
            {
                path: 'calculate',
                component: () =>
                    import('@/views/CreditPisCofins/GenerateCalc.vue'),
                meta: {
                    title: 'Gerar cálculos',
                },
            },
            {
                path: 'export-xml-list',
                component: () =>
                    import('@/views/CreditPisCofins/ListImportsXMLList.vue'),
                meta: {
                    title: 'Exportar Lista de XML',
                },
            },
            {
                path: 'export-calculations',
                component: () => import('@/views/CreditPisCofins/ListCalc.vue'),
                meta: {
                    title: 'Exportar cálculos',
                },
            },
        ],
    },
    {
        path: '/certificates/upload/:token',
        type: 'public',
        component: () =>
            import('@/views/XmlNfe/public/CertificateImportation.vue'),
        meta: {
            title: 'Importação de Certificado',
        },
    },
    // TODO: adjust not found route
    //   {
    //     path: "*",
    //     alias: "not-found",
    //     component: NotFound,
    //     name: "nao-encontrado",
    //     meta: {
    //       title: "Não Encontrado",
    //     },
    //   },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(async (to, from, next) => {
    document.title = `Controlsul - ${to.meta.title}`
    let jwt = Auth()
    if (
        jwt &&
        jwt.iss === 'Controlsul' &&
        jwt.exp > new Date().getTime() / 1000
    ) {
        if (to.path === '/login')
            next({
                path: '/',
            })
        next()
    } else {
        if (to.path.includes('/reset-password')) next()
        if (to.path.startsWith('/certificates/upload')) next()
        if (to.path !== '/login')
            next({
                path: '/login',
            })
        next()
    }
})

export default router
