import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import lang from "quasar/lang/pt-BR.js";
import "./styles/quasar.sass";

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dialog,
    AppFullscreen,
    Loading,
  },
  lang: lang,
  iconSet: iconSet,
};
