<template>
    <q-page-sticky
        position="bottom-right"
        :offset="fabPos"
        v-show="
            !routesWithoutFloatActionButton.find(i => i === $route.name) &&
            !$route.path.startsWith('/certificates')
        "
    >
        <q-btn
            fab
            icon="fas fa-comments"
            class="float-action-button"
            :disable="draggingFab"
            @click="pushTo"
        >
            <q-tooltip class="bg-accent">Fale Conosco</q-tooltip>
        </q-btn>
    </q-page-sticky>
</template>

<script>
export default {
    name: 'FloatButton',

    data() {
        return {
            fabPos: [18, 18],
            draggingFab: false,
            routesWithoutFloatActionButton: [
                'fale-conosco',
                'login',
                'atualizacoes',
                'reset-password',
            ],
        }
    },

    methods: {
        pushTo() {
            if (this.$route.name !== 'fale-conosco') {
                this.$router.push('/')
                this.$router.push('/contact-us')
            }
        },

        moveFab(ev) {
            this.draggingFab = ev.isFirst !== true && ev.isFinal !== true

            this.fabPos = [
                this.fabPos[0] - ev.delta.x,
                this.fabPos[1] - ev.delta.y,
            ]
        },
    },
}
</script>

<style scoped>
.float-action-button {
    background-color: #024e6c;
    color: #fff;
}

.float-action-button-background {
    min-height: 75px !important;
}
</style>
