<template>
    <div
        style="
            max-width: 1200px;
            display: flex;
            flex-direction: column;
            align-items: center;
        "
    >
        <div>
            <h2
                class="text-weight-bold text-secondary"
                :class="$q.screen.gt.lg ? 'text-h4' : 'text-h5 q-mt-none'"
                style="margin-bottom: 0px"
            >
                {{ title }}
            </h2>
            <h3
                class="text-weigth-bold text-light-blue-8"
                :class="$q.screen.gt.lg ? 'text-h6' : 'text-subtitle1'"
                style="margin-top: 0px"
            >
                {{ subtitle }}
            </h3>
        </div>
        <div style="display: flex; flex-wrap: wrap; justify-content: center">
            <div
                :class="$q.screen.gt.lg ? 'gt-lg-div' : 'lt-lg-div'"
                v-for="item in items"
                :key="item.title"
                v-show="item.show"
                @click="$router.push(item.path)"
            >
                <q-icon
                    :name="item.icon"
                    color="secondary"
                    :size="$q.screen.gt.lg ? 'lg' : 'sm'"
                    style="margin-bottom: 1rem"
                />
                <p
                    class="text-secondary text-weight-bold"
                    :class="$q.screen.gt.lg ? 'text-h6' : 'text-subtitle1'"
                    style="margin-bottom: 0px"
                >
                    {{ item.title }}
                </p>
                <p
                    class="text-subtitle1 text-light-blue-8"
                    :class="
                        $q.screen.gt.lg ? 'text-subtitle1' : 'text-subtitle2'
                    "
                    style="margin-bottom: 0px"
                >
                    {{ item.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomepageMenuGroup',

    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        items: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style>
.gt-lg-div {
    border: 2px solid #dfe2e4;
    border-radius: 20px;
    max-width: 20rem;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px #dfe2e4;
}

.lt-lg-div {
    border: 2px solid #dfe2e4;
    border-radius: 20px;
    max-width: 14rem;
    padding: 0.6rem;
    margin: 0.5rem;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px #dfe2e4;
}
</style>
