<template>
    <q-header class="shadow-5">
        <q-toolbar>
            <q-icon
                color="blue-grey"
                name="fas fa-bars"
                size="sm"
                @mouseover="openDrawer"
            />
            <img src="@/assets/nova-logo-controlsul.png" class="logo" />
            <q-separator class="text-secondary" vertical inset />
            <q-toolbar-title class="text-secondary">
                <b style="font-size: 20px">{{ title }}</b>
            </q-toolbar-title>
            <q-space />
            <div v-for="btn of options" :key="btn.name" class="text-secondary">
                <q-btn-dropdown
                    stretch
                    flat
                    :label="btn.name"
                    v-if="btn.type === 'dropdown2'"
                    :icon="btn.icon"
                    size="10px"
                >
                    <q-list>
                        <q-item-label header>{{ btn.name }}</q-item-label>
                        <q-item v-for="item of btn.items" :key="item.name">
                            <q-item-section>
                                <q-item-label>{{ item.name }}</q-item-label>
                                <q-item-label caption>{{
                                    item.caption
                                }}</q-item-label>

                                <q-list>
                                    <q-item
                                        v-for="opt of item.options"
                                        :key="opt.name"
                                        clickable
                                        v-close-popup
                                        @click="() => handleClick(opt.value)"
                                    >
                                        <q-item-section avatar>
                                            <q-avatar
                                                :icon="opt.icon"
                                                color="secondary"
                                                text-color="white"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>{{
                                                opt.name
                                            }}</q-item-label>
                                            <q-item-label caption>{{
                                                opt.caption
                                            }}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-btn-dropdown
                    stretch
                    flat
                    :label="btn.name"
                    v-if="btn.type === 'dropdown'"
                    :icon="btn.icon"
                    size="10px"
                >
                    <q-list>
                        <q-item-label header>{{ btn.name }}</q-item-label>
                        <q-item
                            v-for="item of btn.items"
                            :key="item.name"
                            clickable
                            v-close-popup
                            @click="() => handleClick(item.value)"
                        >
                            <q-item-section avatar>
                                <q-avatar
                                    :icon="item.icon"
                                    color="secondary"
                                    text-color="white"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ item.name }}</q-item-label>
                                <q-item-label caption>{{
                                    item.caption
                                }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-btn
                    v-if="btn.type === 'simple'"
                    stretch
                    flat
                    :label="btn.name"
                    :icon="btn.icon"
                    size="10px"
                    @click="() => handleClick(btn.value)"
                />
            </div>
            <div class="text-secondary">
                <q-btn
                    stretch
                    flat
                    label="Perfil"
                    icon="fas fa-user"
                    size="10px"
                    @click="pushAccount"
                    data-test="header-account-button"
                /><q-btn
                    stretch
                    v-show="validPage"
                    flat
                    label="Ajuda"
                    icon="fas fa-question"
                    size="10px"
                    @click="pushHelp()"
                >
                </q-btn>
            </div>
        </q-toolbar>
    </q-header>
</template>

<script>
import { jwtDecode } from 'jwt-decode'

export default {
    name: 'Header',

    props: {
        options: {
            type: Array,
        },
        pageValue: String,
        validPage: String,

        title: {
            type: String,
        },
        routing: Boolean,
    },

    data() {
        return {
            showPopup: false,
            route: '',
        }
    },

    async created() {
        const { email } = jwtDecode(localStorage.getItem('tk_3000'))
        this.userEmail = this.$store.getters.GET_EMAIL || email
    },

    methods: {
        openDrawer() {
            const drawerState = this.$store.getters.GET_DRAWER
            if (!drawerState) {
                this.$store.dispatch('SET_DRAWER', true)
            }
        },

        handleClick(page) {
            if (this.routing) {
                this.changePage(page)
            } else {
                this.changeTab(page)
            }
        },
        changePage(page) {
            this.$store.dispatch('RESET_STATE')
            this.$router.replace(page)
        },
        changeTab(tab) {
            this.$emit('setMenu', tab)
        },
        goto(refName) {
            var element = this.$refs[refName]
            var top = element.offsetTop

            window.scrollTo(0, top)
        },

        pushAccount() {
            this.$router.push('/account')
        },

        async pushHelp() {
            const currentRoute = this.$route.path
            var pageHelpPusher = this.$router
                .push({ path: `/help/${this.pageValue}` })
                .catch(() => {})

            await pageHelpPusher
            switch (currentRoute) {
                /////////////////
                // FUNDS tabs:
                ////////////////
                case '/funds/import-manad':
                    window.scrollTo(0, 2060)

                    break
                case '/funds/classify-rubrics':
                    window.scrollTo(0, 4250)

                    break
                case '/funds/generate-calculations':
                    window.scrollTo(0, 5620)

                    break
                case '/funds/export-rubrics':
                    window.scrollTo(0, 8280)

                    break
                case '/funds/list-calculations':
                    window.scrollTo(0, 9250)

                    break
                /////////////////
                // SUBSIDY tabs:
                ////////////////
                case '/subsidy/import-sped':
                    window.scrollTo(0, 2500)

                    break
                case '/subsidy/calculate-simplified':
                    window.scrollTo(0, 5200)

                    break
                case '/subsidy/generate-items-data':
                    window.scrollTo(0, 7310)

                    break
                case '/subsidy/classify-items':
                    window.scrollTo(0, 9030)

                    break
                case '/subsidy/generate-calculations':
                    window.scrollTo(0, 9800)

                    break
                case '/subsidy/data-validation-list':
                    window.scrollTo(0, 12000)

                    break
                case '/subsidy/calculations-list':
                    window.scrollTo(0, 12900)

                    break
                //////////////////
                // PIS/COFINS tabs:
                //////////////////
                case '/credit-pis-cofins/import-sped':
                    window.scrollTo(0, 850)

                    break
                case '/credit-pis-cofins/import-xml':
                    window.scrollTo(0, 4350)

                    break
                case '/credit-pis-cofins/xml-list':
                    window.scrollTo(0, 5725)

                    break
                case '/credit-pis-cofins/calculate':
                    window.scrollTo(0, 10130)

                    break
                case '/credit-pis-cofins/export-xml-list':
                    window.scrollTo(0, 14100)

                    break
                case '/credit-pis-cofins/export-calculations':
                    window.scrollTo(0, 15000)

                    break
                //////////////////
                // Simple tabs:
                //////////////////
                case 'simple_cad_client':
                    window.scrollTo(0, 600)

                    break
                case '/simple/import-sped':
                    window.scrollTo(0, 800)

                    break
                case '/simple/analyze-suppliers':
                    window.scrollTo(0, 4760)

                    break
                case '/simple/classify-products':
                    window.scrollTo(0, 7700)

                    break
                case '/simple/generate-calculations':
                    window.scrollTo(0, 10150)

                    break
                case '/simple/export-supplier-analysis-list':
                    window.scrollTo(0, 15200)

                    break
                case '/simple/export-calculations-list':
                    window.scrollTo(0, 16310)

                    break
                default:
                    window.scrollTo(0, 0)

                    break
            }
        },
    },
}
</script>

<style>
span.block {
    font-weight: bold;
}

.teste {
    background-color: red;
}
.logo {
    margin-right: 0.7rem;
    margin-left: 1rem;
}
</style>
