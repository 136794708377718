<template>
    <q-layout view="hHh Lpr lff" id="app">
        <q-drawer
            v-if="isLogin"
            v-model="drawerState"
            :width="300"
            class="text-secondary"
            bordered
            elevated
            @mouseleave="closeDrawer"
        >
            <q-list class="q-mt-md q-mx-md q-mb-xl">
                <MenuSection :items="[menu[0]]" />
                <MenuSection
                    :items="cadastros"
                    sectionName="Cadastros"
                    v-if="cadastros.length > 0"
                />
                <MenuSection
                    :items="ferramentas"
                    sectionName="Ferramentas"
                    v-if="ferramentas.length > 0"
                />
                <MenuSection
                    :items="calculos"
                    sectionName="Cálculos"
                    v-if="calculos.length > 0"
                />
            </q-list>
            <q-footer class="q-mt-lg">
                <q-item>
                    <q-item-section avatar>
                        <q-icon
                            name="fas fa-code-branch"
                            class="footer-version"
                        />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="footer-version">{{
                            versionModule
                        }}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-footer>
        </q-drawer>

        <q-page-container style="padding-bottom: 0px">
            <q-dialog
                v-model="hasConnection"
                persistent
                transition-show="scale"
                transition-hide="scale"
            >
                <q-card style="width: 50%" class="columns items-center">
                    <q-card-section>
                        <div class="text-h6 text-bold">
                            Sem conexão com a internet
                            <q-icon
                                name="fas fa-wifi"
                                class="q-pb-sm q-pl-sm"
                            />
                        </div>
                    </q-card-section>
                    <q-card-section>
                        Percebemos que você está OFFLINE. Logo, todos os
                        recursos da aplicação estão indisponíveis, entre em
                        contato com o suporte.
                    </q-card-section>
                </q-card>
            </q-dialog>
            <router-view :key="$route.fullPath" />
            <float-action-button />
            <FloatEditNewsButton v-if="isArticleAdmin" />
        </q-page-container>
    </q-layout>
</template>

<script>
import { Loading } from 'quasar'
import { watch } from 'vue'
import { mapGetters } from 'vuex'
import FloatActionButton from './components/FloatActionButton.vue'
import FloatEditNewsButton from './components/FloatEditNewsButton.vue'
import MenuSection from './components/MenuSection.vue'
import http from './http'
import { Auth } from './utility'

export default {
    name: 'App',

    components: {
        FloatActionButton,
        FloatEditNewsButton,
        MenuSection,
    },

    data() {
        return {
            currentPath: '',
            showMenu: true,
            isArticleAdmin: false,
            menu: [
                {
                    function: () => this.pushTo('/'),
                    name: 'Página principal',
                    pathname: 'home',
                    show: true,
                    caption: 'Página principal',
                    icon: 'fas fa-home',
                    startsWith: '/home',
                    type: 'home',
                },
                {
                    function: () => this.pushTo('/clients'),
                    name: 'Clientes',
                    pathname: 'clientes',
                    show: true,
                    caption: 'Cadastro de clientes',
                    icon: 'fas fa-user',
                    startsWith: '/clients',
                    type: 'cadastro',
                },
                {
                    function: () => this.pushTo('/users'),
                    name: 'Usuários',
                    pathname: 'usuarios',
                    show: false,
                    caption: 'Gerenciar usuários',
                    icon: 'fas fa-user-cog',
                    startsWith: '/users',
                    type: 'cadastro',
                },
                {
                    function: () => this.pushTo('/database-update'),
                    name: 'Produtos',
                    pathname: 'atualizacao-de-base',
                    show: false,
                    caption: 'Atualização de base',
                    icon: 'fas fa-database',
                    startsWith: '/database-update',
                    type: 'cadastro',
                },
                {
                    function: () => this.pushTo('/xml-nfe'),
                    name: 'XML NFe',
                    pathname: 'xml-nfe',
                    show: false,
                    caption: 'Download de XML NFe',
                    icon: 'fas fa-file-download',
                    startsWith: '/xml-nfe',
                    type: 'ferramentas',
                },
                {
                    function: () => this.pushTo('/checker-simple'),
                    name: 'Consulta ao Simples',
                    pathname: 'consulta-ao-simples',
                    show: false,
                    caption:
                        'Consulta de empresas optantes pelo Simples Nacional e SIMEI',
                    icon: 'fas fa-search',
                    startsWith: '/checker-simple',
                    type: 'ferramentas',
                },
                {
                    function: () => this.pushTo('/icms-fine-diagnosis'),
                    name: 'Diagnóstico de ICMS',
                    pathname: 'icms-fine-diagnosis',
                    show: false,
                    caption: 'SPED e Diagnóstico',
                    icon: 'fas fa-gavel',
                    startsWith: '/icms-fine-diagnosis',
                },
                {
                    function: () => this.pushTo('/database-update'),
                    name: 'Atualização de base',
                    pathname: 'atualizacao-de-base',
                    show: false,
                    caption: 'Atualização de base',
                    icon: 'fas fa-database',
                },
                {
                    function: () => this.pushTo('/funds'),
                    name: 'Verbas',
                    pathname: 'verbas',
                    show: false,
                    caption: 'MANAD, rubrica e cálculos',
                    icon: 'fas fa-calculator',
                    startsWith: '/funds',
                    type: 'calculos',
                },
                {
                    function: () => this.pushTo('/subsidy'),
                    name: 'Subvenção',
                    pathname: 'subvencao',
                    show: false,
                    caption: 'SPED, alíquotas e cálculos',
                    icon: 'fas fa-calculator',
                    startsWith: '/subsidy',
                    type: 'calculos',
                },
                {
                    function: () => this.pushTo('/simple'),
                    name: 'Simples Nacional',
                    pathname: 'simples-nacional',
                    show: false,
                    caption: 'SPED, CST e cálculos',
                    icon: 'fas fa-calculator',
                    startsWith: '/simple',
                    type: 'calculos',
                },
                {
                    function: () => this.pushTo('/credit-pis-cofins'),
                    name: 'Pis/Cofins s/ Icms ST',
                    pathname: 'credito-pis-cofins',
                    show: false,
                    caption: 'SPED, xml e cálculos',
                    icon: 'fas fa-calculator',
                    startsWith: '/credit-pis-cofins',
                    type: 'calculos',
                },
                {
                    function: () => this.pushTo('/icms-fine-diagnosis'),
                    name: 'Diagnóstico de ICMS',
                    pathname: 'icms-fine-diagnosis',
                    show: false,
                    caption: 'SPED e Diagnóstico',
                    icon: 'fas fa-calculator',
                    startsWith: '/icms-fine-diagnosis',
                    type: 'calculos',
                },
                {
                    function: () => this.pushTo('/food-basket'),
                    name: 'Transf. de Itens Isentos',
                    pathname: 'food-basket',
                    show: false,
                    caption: 'SPED e Produtos e Cálculos',
                    icon: 'fas fa-calculator',
                    startsWith: '/food-basket',
                    type: 'calculos',
                },
            ],
            hasConnection: false,
            calledApi: false,
        }
    },

    computed: {
        ...mapGetters(['GET_PERMISSIONS']),
        isLogin() {
            return (
                this.currentPath !== '/login' &&
                !this.currentPath.startsWith('/reset-password')
            )
        },
        versionModule() {
            let hasRoute =
                this.GET_PERMISSIONS &&
                this.GET_PERMISSIONS.length > 0 &&
                this.GET_PERMISSIONS.find(i => i.route === this.$route.name)
            return hasRoute && hasRoute.version ? hasRoute.version : `1.0.0`
        },

        cadastros() {
            return this.menu.filter(i => i.type === 'cadastro' && i.show)
        },

        ferramentas() {
            return this.menu.filter(i => i.type === 'ferramentas' && i.show)
        },

        calculos() {
            return this.menu.filter(i => i.type === 'calculos' && i.show)
        },

        drawerState() {
            return this.$store.getters.GET_DRAWER
        },
    },

    async created() {
        window.addEventListener('offline', () => {
            this.hasConnection = true
            Loading.hide()
        })
        window.addEventListener('online', () => (this.hasConnection = false))
    },

    async mounted() {
        watch(
            () => this.$router.currentRoute.value,
            to => {
                this.currentPath = to.fullPath
                this.mountMenu()
            },
        )
    },

    async beforeUpdate() {
        if (!this.calledApi) {
            await this.mountMenu()
        }
    },

    methods: {
        closeDrawer() {
            const drawerState = this.$store.getters.GET_DRAWER
            if (drawerState) this.$store.dispatch('SET_DRAWER', false)
        },

        pushTo(route) {
            if (this.currentPath === route) return
            this.$router.push(route)
        },

        async mountMenu() {
            let jwt = Auth()
            if (
                jwt &&
                jwt.iss === 'Controlsul' &&
                jwt.exp > new Date().getTime() / 1000
            ) {
                // if (this.GET_PERMISSIONS && this.GET_PERMISSIONS.length > 0) {
                //     this.menu.forEach(i => {
                //         if (
                //             this.$store.getters.GET_PERMISSIONS.includes(
                //                 i.pathname,
                //             )
                //         )
                //             i.show = true
                //     })
                // } else {
                const response = await http.get(
                    `${window.URL_API_NODEJS}/user/permission/${jwt.id}`,
                )
                const permissions = response.data.permissions

                this.isArticleAdmin = permissions.find(
                    j => j.route === 'atualizacoes',
                )

                var { data } = await http.get(`${window.URL_API_NODEJS}/routes`)

                permissions.forEach(i => {
                    var permission = data.find(j => j.id === i.id)
                    i.version = permission.version
                })

                this.menu.forEach(i => {
                    let item = permissions.find(j => j.route === i.pathname)
                    if (item) i.show = true
                })
                this.$store.dispatch('SET_PERMISSIONS', permissions)
                this.calledApi = true
                // }
            }
        },
    },
}
</script>

<style>
* {
    font-family: Arial;
}

body {
    background-color: rgba(2, 78, 108, 0.2);
}

.body-with-image {
    background-image: url('./assets/nova-logo-controlsul.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
}

.logo {
    width: 100px !important;
    cursor: pointer;
}

.simple-page,
.page {
    background-color: #fff;
    background-image: none;
    color: #024e6c;
}

.button-large {
    width: 100% !important;
    height: 55px !important;
    font-weight: bold !important;
}

.input {
    height: 56px !important;
}

.q-item__label {
    font-weight: bold;
}

.footer-version {
    color: #024e6c;
}

footer {
    left: 0px !important;
}
</style>
