<template>
    <q-page-sticky
        position="bottom-right"
        :offset="fabPos"
        v-show="routesWithTheButton.find(i => i === $route.name)"
    >
        <q-btn
            fab
            icon="fas fa-pen"
            class="float-action-button"
            :disable="draggingFab"
            @click="pushTo"
        >
            <q-tooltip class="bg-accent">Gerenciar Atualizações</q-tooltip>
        </q-btn>
    </q-page-sticky>
</template>

<script>
export default {
    name: 'FloatButton',

    data() {
        return {
            fabPos: [18, 90],
            draggingFab: false,
            routesWithTheButton: ['subvencao', 'verbas', 'simples-nacional'],
        }
    },

    methods: {
        pushTo() {
            this.$router.push('/')
            this.$router.push('/news/edit')
        },

        moveFab(ev) {
            this.draggingFab = ev.isFirst !== true && ev.isFinal !== true

            this.fabPos = [
                this.fabPos[0] - ev.delta.x,
                this.fabPos[1] - ev.delta.y,
            ]
        },
    },
}
</script>

<style scoped>
.float-action-button {
    background-color: #024e6c;
    color: #fff;
}

.float-action-button-background {
    min-height: 75px !important;
}
</style>
