const initialState = {
    clientParams: null,
    subsParams: null,
    init: '',
    end: '',
}

const state = { ...initialState }

const mutations = {
    SET_CLIENT_PARAMS(state, payload) {
        state.clientParams = payload
    },
    SET_SUBS_PARAMS(state, payload) {
        state.subsParams = payload
    },
    SET_INIT(state, payload) {
        state.init = payload
    },
    SET_END(state, payload) {
        state.end = payload
    },
    RESET_STATE(state) {
        Object.assign(state, initialState)
    },
}

const getters = {
    GET_CLIENT_PARAMS(state) {
        return state.clientParams
    },
    GET_SUBS_PARAMS(state) {
        return state.subsParams
    },
    GET_INIT(state) {
        return state.init
    },
    GET_END(state) {
        return state.end
    },
}

const actions = {
    UPDATE_CLIENT_PARAMS({ commit }, payload) {
        commit('SET_CLIENT_PARAMS', payload)
    },
    UPDATE_SUBS_PARAMS({ commit }, payload) {
        commit('SET_SUBS_PARAMS', payload)
    },
    UPDATE_INIT({ commit }, payload) {
        commit('SET_INIT', payload)
    },
    UPDATE_END({ commit }, payload) {
        commit('SET_END', payload)
    },
    RESET_STATE({ commit }) {
        commit('RESET_STATE')
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
}
