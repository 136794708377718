import http from '../http'

const state = {
    permissions: [],
    email: '',
    user: {},
}
const mutations = {
    ADD_PERMISSIONS(state, permissions) {
        state.permissions = permissions
    },
    ADD_EMAIL(state, email) {
        state.email = email
    },
    ADD_USER(state, user) {
        state.user = user
    },
}
const getters = {
    GET_PERMISSIONS(state) {
        return state.permissions
    },
    GET_EMAIL(state) {
        return state.email
    },
    GET_USER(state) {
        return state.user
    },
}
const actions = {
    SET_PERMISSIONS({ commit }, payload) {
        commit('ADD_PERMISSIONS', payload)
    },
    SET_EMAIL({ commit }, payload) {
        commit('ADD_EMAIL', payload)
    },
    async SET_USER({ commit }) {
        const response = await http.get(`${window.URL_API_NODEJS}/user`)
        commit('ADD_USER', response.data)
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
}
