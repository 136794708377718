<template>
    <q-item-label header v-if="sectionName">{{ sectionName }}</q-item-label>
    <q-item
        clickable
        v-for="item of computedItems"
        :key="item.name"
        :active="item.active"
        :style="{
            'background-color':
                $route.path.startsWith(item.startsWith) || item.active
                    ? '#DBEDF5'
                    : '#fff',
            color: '#00405f',
            'border-radius': '54px',
            'margin-bottom': '2px',
        }"
        @click="item.function"
        v-show="item.show"
    >
        <q-item-section avatar>
            <q-icon :name="item.icon" />
        </q-item-section>
        <q-item-section>
            <q-item-label>{{ item.name }}</q-item-label>
        </q-item-section>
    </q-item>
</template>

<script>
export default {
    name: 'MenuSection',

    props: {
        items: Array,
        sectionName: String,
    },

    computed: {
        computedItems() {
            return this.items.map(item => {
                if (this.$route.name === item.pathname) {
                    item.active = true
                } else {
                    item.active = false
                }
                return item
            })
        },
    },
}
</script>
