import { jwtDecode } from 'jwt-decode'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

export function Auth() {
    return localStorage.getItem('tk_3000')
        ? jwtDecode(localStorage.getItem('tk_3000'))
        : null
}

export function MyNotify(message, type = 'negative', caption = '') {
    return Notify.create({
        type: type,
        message: message,
        position: 'top',
        color: type,
        timeout: 5000,
        progress: true,
        caption: caption,
    })
}

export function MyNotify2(message, type = 'positive', caption = '') {
    return Notify.create({
        type: type,
        message: message,
        position: 'top',
        color: type,
        timeout: 5000,
        progress: true,
        caption: caption,
    })
}

export function MyDialog(title, message) {
    return Dialog.create({
        message: message,
        title: title,
        color: 'secondary',
        cancel: true,
        persistent: true,
    })
}

export function searchArray(array, key, value) {
    var cont = 0
    var indices = []
    for (var i in array) {
        var row = array[i]
        if (row[key] == value) {
            indices.push(cont)
        }
        cont++
    }
    return indices
}

export function UUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c =>
        (c === 'x'
            ? (Math.random() * 16) | 0
            : (Math.random() * 16) | (0 & 0x3) | 0x8
        ).toString(16),
    )
}

export function GetRangeOfDates(dateI, dateE) {
    const transformDate = d => new Date(d)
    const getRange = (dateI, dateE) => {
        let getFirstMonth = dateI.getMonth()
        let dates = new Array()
        let currentDate = dateI
        while (currentDate <= dateE) {
            dates.push(currentDate)
            let date = new Date(currentDate)
            date.setMonth(++getFirstMonth)
            if (getFirstMonth === 12) getFirstMonth = 0
            currentDate = date
        }
        return dates
    }
    return getRange(transformDate(dateI), transformDate(dateE))
}

export const translator = {
    footerLeft: (top, bottom, total) =>
        `Registros ${top} - ${bottom} de ${total}`,
    first: 'Primeira linha',
    previous: 'Voltar',
    next: 'Próximo',
    last: 'Última linha',
    footerRight: {
        selected: 'Selecionado:',
        filtered: 'Filtrado:',
        loaded: 'Carregado:',
    },
    processing: 'Procesando',
    tableSetting: 'Configurações',
    exportExcel: 'Exportar Excel',
    importExcel: 'Importar Excel',
    back: 'Cancelar',
    reset: 'Resetar',
    sortingAndFiltering: 'Ordenar e Filtrar',
    sortAscending: 'Ordenar crescente',
    sortDescending: 'Ordenar decrescente',
    near: '≒ Contém',
    exactMatch: '= É igual',
    startsWith: '^ Inicia com',
    notMatch: '≠ Não é igual',
    greaterThan: '&gt; Maior que',
    greaterThanOrEqualTo: '≥ Maior ou igual a que',
    lessThan: '&lt; Menor que',
    lessThanOrEqualTo: '≤ Menor ou igual a que',
    regularExpression: '~ Expressão regular',
    customFilter: 'Filtro customizado',
    listFirstNValuesOnly: n => `List first ${n} values only`,
    apply: 'Aplicar',
    noRecordIsRead: 'Nenhum registro foi lido',
    readonlyColumnDetected: 'Coluna de apenas leitura',
    columnHasValidationError: (name, err) =>
        `Coluna ${name} tem erros na validação da informação: ${err}`,
    noMatchedColumnName: 'Coluna não encontrada',
    invalidInputValue: 'Dados inválidos',
    missingKeyColumn: 'Falta a chave da coluna',
    noRecordIndicator: 'Nenhum registro',
}
