<template>
    <q-page class="body-with-image q-ma-lg" data-test="home-page">
        <Header title="Home" :options="[]" />
        <q-page
            class="bg-white"
            :class="$q.screen.gt.lg ? 'q-pa-xl' : 'q-pa-none'"
            style="background-color: white"
        >
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                "
            >
                <div>
                    <h1
                        class="text-h3 text-weight-bold text-secondary"
                        style="margin-bottom: 0px"
                        :class="
                            $q.screen.gt.lg
                                ? 'text-h3 q-mt-md'
                                : 'text-h4 q-mt-lg'
                        "
                    >
                        Bem-vindo ao sistema cálculos
                    </h1>
                    <h2
                        class="text-h6 text-weight-bold text-light-blue-8"
                        style="margin-top: 0px"
                        :class="
                            $q.screen.gt.lg
                                ? 'text-h6'
                                : 'text-subtitle1 q-mb-md'
                        "
                    >
                        A ferramenta interna da Controlsul para cálculo de teses
                        e utilização de produtos.
                    </h2>
                </div>
                <div
                    style="border-bottom: 2px solid #dfe2e4"
                    :class="$q.screen.gt.lg ? 'q-mt-md' : 'q-mt-lg'"
                >
                    <q-btn
                        :color="
                            activeTab === 'calculus'
                                ? 'secondary'
                                : 'light-blue-8'
                        "
                        icon="fas fa-calculator"
                        label="Cálculos"
                        @click="activeTab = 'calculus'"
                        stack
                        flat
                    />
                    <q-btn
                        :color="
                            activeTab === 'tools' ? 'secondary' : 'light-blue-8'
                        "
                        icon="fas fa-tools"
                        label="Ferramentas"
                        @click="activeTab = 'tools'"
                        stack
                        flat
                    />
                    <q-btn
                        :color="
                            activeTab === 'cadastral'
                                ? 'secondary'
                                : 'light-blue-8'
                        "
                        icon="fas fa-box"
                        label="Cadastros"
                        @click="activeTab = 'cadastral'"
                        stack
                        flat
                    />
                </div>
                <q-tab-panels
                    v-model="activeTab"
                    animated
                    :class="$q.screen.gt.lg ? '' : 'q-mt-lg'"
                >
                    <q-tab-panel name="calculus">
                        <HomepageMenuGroup
                            :title="calculusTitle"
                            :subtitle="calculusSubtitle"
                            :items="calculus"
                        />
                    </q-tab-panel>
                    <q-tab-panel name="tools">
                        <HomepageMenuGroup
                            :title="toolsTitle"
                            :subtitle="toolsSubtitle"
                            :items="tools"
                        />
                    </q-tab-panel>
                    <q-tab-panel name="cadastral">
                        <HomepageMenuGroup
                            :title="cadastralTitle"
                            :subtitle="cadastralSubtitle"
                            :items="cadastral"
                        />
                    </q-tab-panel>
                </q-tab-panels>
            </div>
        </q-page>

        <q-dialog v-model="showModal" :maximized="true" persistent>
            <UpdatesModal
                @closeModal="showModal = false"
                :newsData="newsData"
                :isInitialPage="true"
            />
        </q-dialog>
    </q-page>
</template>

<script>
import Header from '@/components/Header'
import HomepageMenuGroup from '@/components/HomepageMenuGroup'
import UpdatesModal from '@/components/UpdatesModal.vue'
import http from '@/http'
import { Auth } from '@/utility'
import { watch } from 'vue'

export default {
    name: 'Home',

    components: {
        Header,
        HomepageMenuGroup,
        UpdatesModal,
    },

    beforeMount() {
        this.tabNewsCheck()
    },

    data() {
        return {
            activeTab: 'calculus',
            showModal: false,
            newsData: null,
            calculus: [
                {
                    icon: 'fas fa-calculator',
                    title: 'Verbas',
                    description:
                        'Análise detalhada de verbas rescisórias e trabalhistas',
                    path: '/funds',
                    pathname: 'verbas',
                    show: false,
                },
                {
                    icon: 'fas fa-calculator',
                    title: 'Subvenção',
                    description:
                        'Inicie o processo de cálculo para a tese de subvenção.',
                    path: '/subsidy',
                    pathname: 'subvencao',
                    show: false,
                },
                {
                    icon: 'fas fa-calculator',
                    title: 'Simples Nacional',
                    description:
                        'Cálculo e otimização tributária para o Simples Nacional',
                    path: '/simple',
                    pathname: 'simples-nacional',
                    show: false,
                },
                {
                    icon: 'fas fa-calculator',
                    title: 'Pis/Cofins s/ Icms ST',
                    description:
                        'Calcule o crédito de Pis/Cofins sobre o ICMS ST',
                    path: '/credit-pis-cofins',
                    pathname: 'credito-pis-cofins',
                    show: false,
                },
                {
                    icon: 'fas fa-calculator',
                    title: 'Diagnóstico de ICMS',
                    description:
                        'Realize o diagnóstico de ICMS, por CSTs e Alíquotas, nas entradas',
                    path: '/icms-fine-diagnosis',
                    pathname: 'icms-fine-diagnosis',
                    show: false,
                },
                {
                    icon: 'fas fa-calculator',
                    title: 'Transf. de Itens Isentos',
                    description:
                        'Crédito de itens isentos com base em transferências entre filiais da empresa',
                    path: '/food-basket',
                    pathname: 'food-basket',
                    show: false,
                },
            ],
            calculusTitle: 'Navegue pelos cálculos disponíveis',
            calculusSubtitle:
                'Acesse rapidamente os principais cálculos do sistema.',
            tools: [
                {
                    icon: 'fas fa-file-download',
                    title: 'Download de XML NFe',
                    description:
                        'Baixe automaticamente os XMLs de fiscais eletrônicas',
                    path: '/xml-nfe',
                    pathname: 'xml-nfe',
                    show: false,
                },
                {
                    icon: 'fas fa-search',
                    title: 'Consulta ao Simples',
                    description: 'Realize a consulta ao simples nacional',
                    path: '/checker-simple',
                    pathname: 'consulta-ao-simples',
                    show: false,
                },
            ],
            toolsTitle: 'Navegue pelas ferramentas disponíveis',
            toolsSubtitle:
                'Acesse rapidamente as principais ferramentas do sistema.',
            cadastral: [
                {
                    icon: 'fas fa-user',
                    title: 'Cadastro de Clientes',
                    description:
                        'Crie e atualize os clientes dentro do sistema cálculos',
                    path: '/clients',
                    pathname: 'clientes',
                    show: true,
                },
                {
                    icon: 'fas fa-user-cog',
                    title: 'Cadastro de Usuários',
                    description:
                        'Cadastre e atualize os usuários do sistema cálculos',
                    path: '/users',
                    pathname: 'usuarios',

                    show: false,
                },
                {
                    icon: 'fas fa-database',
                    title: 'Cadastro de Produtos',
                    description:
                        'Classifique e atualize produtos na base de dados Controlsul',
                    path: '/database-update',
                    pathname: 'atualizacao-de-base',

                    show: false,
                },
                {
                    icon: 'fas fa-pen',
                    title: 'Cadastro de Atualizações',
                    description: 'Gerencie as atualizações do sistema cálculos',
                    path: '/news/edit',
                    pathname: 'atualizacoes',
                    show: false,
                },
            ],
            cadastralTitle: 'Navegue pelos cadastros disponíveis',
            cadastralSubtitle:
                'Acesse rapidamente os principais cálculos do sistema.',
        }
    },

    async mounted() {
        watch(this.mountMenu())
    },

    methods: {
        async mountMenu() {
            let jwt = Auth()
            console.log('Passando no auth menu')

            if (
                jwt &&
                jwt.iss === 'Controlsul' &&
                jwt.exp > new Date().getTime() / 1000
            ) {
                const response = await http.get(
                    `${window.URL_API_NODEJS}/user/permission/${jwt.id}`,
                )
                const permissions = response.data.permissions

                this.calculus.forEach(i => {
                    let item = permissions.find(j => j.route === i.pathname)
                    if (item) i.show = true
                })
                this.tools.forEach(i => {
                    let item = permissions.find(j => j.route === i.pathname)
                    if (item) i.show = true
                })
                this.cadastral.forEach(i => {
                    let item = permissions.find(j => j.route === i.pathname)
                    if (item) i.show = true
                })
            }
        },

        async tabNewsCheck() {
            try {
                const response = await http.get(
                    `${window.URL_API_NODEJS}/article/initial`,
                )
                if (response.data.length > 0) {
                    this.showModal = true
                    this.newsData = response.data
                }
            } catch (e) {
                return console.log(e)
            }
        },
    },

    computed: {},
}
</script>

<style></style>
