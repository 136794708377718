<template>
    <q-card class="q-pa-xl container">
        <q-btn
            class="close-button absolute-top-right q-mr-md q-mt-md"
            round
            size="md"
            @click="closeModal"
            icon="fas fa-times"
            style="color: #024e6c"
        />
        <p style="font-size: 18px" align="center" v-if="isInitialPage">
            <b>Atualizações do Sistema</b>
            <br />
        </p>
        <p style="font-size: 18px" align="center" v-else>
            <b>Atualizações do módulo de {{ mainTitle }}</b>
            <br />
        </p>
        <p align="center">Veja, abaixo, as últimas novidades</p>
        <div style="color: #024e6c">
            <q-card flat style="border-radius: 0px">
                <div>
                    <div class="row" style="padding: 5px">
                        <div class="col" style="padding-left: 1.5%">
                            Postado em
                        </div>
                        <div class="col" style="padding-right: 51%">Título</div>
                        <div class="col" style="padding-right: 1%">
                            Categoria
                        </div>
                    </div>

                    <div
                        style="padding: 2px"
                        v-for="article of articleList"
                        :key="article.id"
                    >
                        <q-list bordered>
                            <q-expansion-item>
                                <template v-slot:header class="row">
                                    <q-item-section class="col-1"
                                        ><b style="color: grey">{{
                                            article.created_at
                                        }}</b></q-item-section
                                    >
                                    <q-item-section class="col-9"
                                        ><b
                                            ><i class="fas fa-bell padding" />
                                            {{ article.title }}</b
                                        >
                                        <div
                                            style="
                                                color: grey;
                                                padding-left: 37px;
                                            "
                                        >
                                            {{ article.desc }}
                                        </div>
                                    </q-item-section>
                                    <q-item-section>
                                        <b
                                            class="col-4"
                                            style="color: grey"
                                            align="left"
                                            >{{ article.type }}</b
                                        >
                                    </q-item-section>
                                </template>
                                <q-separator />
                                <q-card>
                                    <q-card-section>
                                        <p
                                            v-html="article.content"
                                            style="white-space: pre-line"
                                            class="justify"
                                        ></p>
                                    </q-card-section>
                                    <q-item-section>
                                        <q-separator />
                                        <q-btn
                                            flat
                                            @click="dontShowAgain(article.id)"
                                            class="col"
                                            label="Não mostrar mais"
                                            bordered
                                            v-model="value"
                                            color="secondary"
                                        />
                                    </q-item-section>
                                </q-card>
                            </q-expansion-item>
                        </q-list>
                    </div>
                </div>
            </q-card>
        </div>
    </q-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import http from '../http'
import { MyNotify2 } from '../utility'

export default {
    name: 'UpdatesModal',

    data() {
        return {
            value: this.val,
            articleList: [],
            checkPermission: false,
        }
    },

    created() {
        this.SET_PERMISSIONS()
    },

    beforeMount() {
        this.getArticlesData()
    },

    setup() {
        return {
            val: false,
        }
    },
    props: {
        val: Boolean,
        mainTitle: String,
        isInitialPage: Boolean,
        newsData: {
            type: Array,
        },
    },

    computed: {
        ...mapGetters(['GET_PERMISSIONS']),

        options() {
            const route =
                this.GET_PERMISSIONS &&
                this.GET_PERMISSIONS.length === 0 &&
                this.GET_PERMISSIONS.find(i => i.route === this.$route.name)
            if (route && route.level > 0) {
                this.menus.forEach(i => {
                    if (i.level <= route.level) i.show = true
                    return i
                })
            }
            return this.menus.filter(i => i.show === true)
        },
    },

    components: {},

    methods: {
        ...mapActions(['SET_PERMISSIONS']),

        strToDate(str) {
            return str
                ? new Date(
                      str.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$2-$1'),
                  )
                : null
        },

        sorting() {
            this.articleList.sort((a, b) =>
                this.strToDate(a.created_at) < this.strToDate(b.created_at)
                    ? 1
                    : -1,
            )
        },

        popRouter() {
            this.$emit('pushRouter', null)
        },

        async getArticlesData() {
            try {
                this.articleList = await Promise.all(
                    this.newsData.map(async item => {
                        const formatedDate = this.dateMask(item.created_at)
                        return {
                            id: item.id,
                            title: item.title,
                            desc: item.desc,
                            content: item.content,
                            type: item.type,
                            category: item.category,
                            created_at: formatedDate,
                            who_read: item.who_read,
                        }
                    }),
                )
                this.sorting()
            } catch (e) {
                console.error(e)
            }
        },

        dateMask(value) {
            const date = new Date(value)
            return (
                String(date.getDate()).padStart(2, '0') +
                '/' +
                String(date.getMonth() + 1).padStart(2, '0') +
                '/' +
                date.getFullYear()
            )
        },

        async dontShowAgain(id) {
            try {
                await http.patch(`${window.URL_API_NODEJS}/article/${id}`)
                MyNotify2('A notícia não será mais mostrada.', 'positive')
                return document.location.reload(true)
            } catch (e) {
                console.error(e)
            }
        },

        goToCreate() {
            this.$router.push('/news/create')
        },

        closeModal() {
            this.$emit('closeModal')
        },
    },
}
</script>

<style scoped>
.left {
    position: relative;
    padding-left: 25px;
    color: #0ebfe9;
    display: table-cell;
    width: 100px;
    vertical-align: middle;
}

.right {
    color: #2a2c2b;
    display: table-cell;
    width: 180px;
    vertical-align: middle;
    padding-left: 14px;
    padding-right: 25px;
}
P.blocktext {
    margin-left: auto;
    margin-right: auto;
    width: 8em;
}
IMG.displayed {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.textcenter {
    text-align: center;
}
.padding {
    padding: 30px;
}
.padding2 {
    padding: 20px;
}
I.padding {
    padding: 10px;
}
.justify {
    text-align: justify;
    text-justify: inter-word;
}
.container {
    width: 75vw;
    height: 80vh;
}
</style>
