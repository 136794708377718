const state = {
    openDrawer: false,
}

const mutations = {
    TOGGLE_DRAWER(state) {
        state.openDrawer = !state.openDrawer
    },
}

const getters = {
    GET_DRAWER(state) {
        return state.openDrawer
    },
}

const actions = {
    SET_DRAWER({ commit }) {
        commit('TOGGLE_DRAWER')
    },
}

export default {
    state,
    mutations,
    getters,
    actions,
}
